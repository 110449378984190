<template>
	<div>
		<Nav @link="linkTo" :idx="1"></Nav>
		<div class="root">
			<div class="title">智慧养老</div>
			<div class="line_block"></div>
			<div class="pension_desc">以智慧健康养老政策为导向，以居家、机构、日照、社区养老为核心，以线上交互线下服务模式，为老年人提供老年人用品、居家养老、机构养老、慢病管理、个人健康数据管理、康复护理、健康咨询、紧急救助，生活服务、家政服务、精神慰藉、安宁照顾等服务，为养老服务机构/企业提供低成本、规范化、可评估、受监管、易扩展的业务信息化平台服务，为政府提供信息实时全面、分析智慧准确、政策高效落实的信息化服务，共同构建一站式智慧健康养老服务体系。</div>
			<div class="title">产品服务</div>
			<div class="line_block"></div>
			<div class="tab_box">
				<div class="cursor" v-for="(item, i) in iconList" :key="i" @click="tapIcon(i, item.id)">
					<div class="icon_box">
						<img :src="item.src" class="icon_img" alt="">
					</div>
					<div :class="['tab_label', {'tab_active': i == iconIdx}]">{{item.title}}</div>
					<!-- <div :class="['tab_label', {'tab_active': i === 0}]">{{item.title}}</div> -->
				</div>
			</div>
			<div :id="item.id" v-for="(item, i) in imgs" :key="i">
				<div class="tab_img_box">
					<div class="img_con" v-for="(itemList, j) in item.imgObj" :key="j">
						<img class="tab_img" :src="itemList.src" alt="">
						<div class="tab_bott">{{itemList.title}}</div>
					</div>
				</div>
				<div class="pension_desc">{{item.desc}}</div>
			</div>
			<!-- <div class="tab_box">
				<div class="cursor" v-for="(item, i) in tabList" :key="i" @mouseenter="mouseEnter(i)">
					<div class="icon_box">
						<img :src="item.src" class="icon_img" alt="">
					</div>
					<div :class="['tab_label', {'tab_active': i === 0}]">{{item.title}}</div>
					<div class="triangle"></div>
				</div>
				<div class="triangle2"><b></b></div>
			</div> -->
			<!-- <div class="tab_link product-wrap">
				<div class="link_content product" :style="{display: i === 0 ? 'block' : 'none'}" v-for="(item, i) in tabList" :key="i">
					<div class="con_left">
						<div class="con_title">{{item.title}}</div>
						<div class="con_subtitle">{{item.subtitle}}</div>
						<div class="con_desc">{{item.desc}}</div>
					</div>
					<div class="con_right">
						<img class="con_img" :src="item.img" alt="">
					</div>
				</div>
			</div> -->
			<!-- <div class="title">智能硬件</div>
			<div class="line_block"></div> -->
			<!-- 轮播 -->
			<!-- <div class="root">
				<swiper :options="swiperOption" v-if="swiperSlides">
					<swiper-slide v-for="(slide, i) in swiperSlides" :key="i">
						<div class="slide_box" v-for="(item, j) in slide" :key="j">
							<img :src="item.src" class="slide_img" alt="">
							<div class="slide_intro">
								<div class="intro_title">{{item.title}}</div>
								<div class="intro_desc">{{item.desc}}</div>
								<div class="rect_top"></div>
								<div class="rect_bottom"></div>
							</div>
						</div>
					</swiper-slide>
					<div class="swiper-button-prev mobile-hide" slot="button-prev"></div>
					<div class="swiper-button-next mobile-hide" slot="button-next"></div>
					Add Pagination
					<div class="swiper-pagination pc-hide" slot="pagination"></div>
				</swiper>
			</div> -->
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'
import $ from 'jquery'

export default {
	name: 'productService',
	components: {
		Nav,
		Footer
	},
	data () {
		return {
			imgs: [
				{
					id: '1',
					desc: '包括：老人动态档案管理、老人评估系统、会员服务管理，对老人基本信息（基本信息、健康信息、亲属信息等）动态管理，并对老人活动能力、认知能力等进行评估，以此对老人制定分级服务计划，发展为会员的用户进行个性化的会员服务管理，如：服务套餐定制，定期回访管理等。',
					imgObj: [
						{
							src: require('@/assets/img/prod1/hy/1.png'),
							title: '动态档案管理',
						},
						{
							src: require('@/assets/img/prod1/hy/2.png'),
							title: '',
						},
						{
							src: require('@/assets/img/prod1/hy/3.png'),
							title: '',
						},
						{
							src: require('@/assets/img/prod1/hy/4.png'),
							title: '老人评估系统',
						},
						{
							src: require('@/assets/img/prod1/hy/5.png'),
							title: '会员服务管理',
						},
						{
							src: require('@/assets/img/prod1/hy/6.png'),
							title: '基本信息',
						},
						{
							src: require('@/assets/img/prod1/hy/7.png'),
							title: '健康信息',
						},
						{
							src: require('@/assets/img/prod1/hy/8.png'),
							title: '亲属信息',
						},
						{
							src: require('@/assets/img/prod1/hy/9.png'),
							title: '老人活动能力',
						},
						{
							src: require('@/assets/img/prod1/hy/10.png'),
							title: '认知能力评估',
						},
						{
							title: '',
						},
						{
							src: require('@/assets/img/prod1/hy/11.png'),
							title: '服务套餐定制',
						},
						{
							src: require('@/assets/img/prod1/hy/12.png'),
							title: '定期回访',
						},
						{
							src: require('@/assets/img/prod1/hy/13.png'),
							title: '分级服务',
						},
						{
							title: '',
						}
					]
				},
				{
					id: '2',
					desc: '包括：呼叫服务、人员定位（室内定位和室外定）、电子围栏、主动报警、紧急救助、睡眠监测、照护计划、独居监护、健康监测。老人一键呼叫，服务快速响应；随时随地预约，操作方便快捷；订单全程跟踪，保障服务质量；健康安全监测，降低独居风险；数据精准分析，指导运营决策；子女实时查看，提高双方沟通。',
					imgObj: [
						{
							src: require('@/assets/img/prod1/zh/1.png'),
							title: '呼叫服务',
						},
						{
							src: require('@/assets/img/prod1/zh/2.png'),
							title: '',
						},
						{
							src: require('@/assets/img/prod1/zh/3.png'),
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/zh/4.png'),
							title: '人员定位',
						},
						{
							src: require('@/assets/img/prod1/zh/5.png'),
							title: '电子围栏',
						},
						{
							src: require('@/assets/img/prod1/zh/6.png'),
							title: '主动报警',
						},
						{
							src: require('@/assets/img/prod1/zh/7.png'),
							title: '紧急救助',
						},
						{
							src: require('@/assets/img/prod1/zh/8.png'),
							title: '睡眠监测',
						},
						{
							src: require('@/assets/img/prod1/zh/9.png'),
							title: '照护计划',
						},
						{
							src: require('@/assets/img/prod1/zh/10.png'),
							title: '独居监护',
						},
						{
							title: '',
						},
						{
							src: require('@/assets/img/prod1/zh/11.png'),
							title: '健康监测',
						},
						{
							src: require('@/assets/img/prod1/zh/12.png'),
							title: '一键呼叫',
						},
						{
							src: require('@/assets/img/prod1/zh/13.png'),
							title: '子女实时查看',
						},
						{
							title: '',
						},
					]
				},
				{
					id: '3',
					desc: '包括：接待管理、收费管理、餐饮管理、入住管理、房态图、护理管理、室内人员定位、用药管理、人事管理、门禁管理。规范业务流程，显著提升效率；降低人力成本，规范运营管理；有效财务控制，增加利润收入；多维统计分析，轻松查看导出；提高运营效率，减少出错机会；提高市场竞争力，提高机构入住率。',
					imgObj: [
						{
							src: require('@/assets/img/prod1/jg/1.png'),
							title: '接待管理',
						},
						{
							src: require('@/assets/img/prod1/jg/2.png'),
							title: '',
						},
						{
							src: require('@/assets/img/prod1/jg/3.png'),
							title: '',
						},
						{
							src: require('@/assets/img/prod1/jg/4.png'),
							title: '收费管理',
						},
						{
							src: require('@/assets/img/prod1/jg/5.png'),
							title: '餐饮管理',
						},
						{
							src: require('@/assets/img/prod1/jg/6.png'),
							title: '入住管理',
						},
						{
							src: require('@/assets/img/prod1/jg/7.png'),
							title: '房态图',
						},
						{
							src: require('@/assets/img/prod1/jg/8.png'),
							title: '护理管理',
						},
						{
							src: require('@/assets/img/prod1/jg/9.png'),
							title: '人员定位',
						},
						{
							src: require('@/assets/img/prod1/jg/10.png'),
							title: '用药管理',
						},
						{
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/jg/11.png'),
							title: '人事管理',
						},
						{
							src: require('@/assets/img/prod1/jg/12.png'),
							title: '门禁管理',
						},
						{
							title: '',
						},
						{
							title: '',
						},
					]
				},
				{
					id: '4',
					desc: '包括：智慧养老呼叫中心系统，提供语音和视频的端到端的呼叫中心云服务，包括呼叫中心系统、云平台部署、客户专线组网、座席端接入、通信资源整合等一揽子服务。基于云服务实现低成本的全量覆盖、质检与呼叫中心一体化，唤醒沉睡的语音数据',
					imgObj: [
						{
							src: require('@/assets/img/prod1/hj/1.png'),
							title: '呼叫中心系统',
						},
						{
							src: require('@/assets/img/prod1/hj/2.png'),
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/hj/3.png'),
							title: ' ',
						},
						{
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/hj/4.png'),
							title: '云平台部署',
						},
						{
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/hj/5.png'),
							title: '客户专线组网',
						},
						{
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/hj/6.png'),
							title: '坐席端接入',
						},
						{
							title: ' ',
						},
					]
				},
				{
					id: '5',
					desc: '定制化政府需求，科学管理信息，全面把控质量，可视化数据呈现；辖区内老人数据实时更新，补贴信息严格监管，政府实时了解老人、社区、服务机构、服务人员、老人健康情况、老人求助情况等统计分析，为政府做出相关决策提供数据依据。',
					imgObj: [
						{
							src: require('@/assets/img/prod1/zf/1.png'),
							title: '定制化政府需求',
						},
						{
							src: require('@/assets/img/prod1/zf/2.png'),
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/zf/3.png'),
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/zf/4.png'),
							title: '可视化数据呈现',
						},
						{
							src: require('@/assets/img/prod1/zf/5.png'),
							title: '服务机构',
						},
						{
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/zf/6.png'),
							title: '服务人员',
						},
						{
							src: require('@/assets/img/prod1/zf/7.png'),
							title: '老人健康情况',
						},
						{
							src: require('@/assets/img/prod1/zf/8.png'),
							title: '老人求助情况',
						},
						{
							title: ' ',
						},
					]
				},
				{
					id: '6',
					desc: '包括：主动关怀、家庭医生、电商（商城）、护理服务、订单服务、健康管理服务、志愿者、时间银行、助洁服务、助行服务。丰富盈利模式，促进消费行为；服务质量可控，全程监督记录；便捷支付方式，方便快捷省心；服务内容周全，深受长者喜爱。',
					imgObj: [
						{
							src: require('@/assets/img/prod1/fw/1.png'),
							title: '主动关怀',
						},
						{
							src: require('@/assets/img/prod1/fw/2.png'),
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/fw/3.png'),
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/fw/4.png'),
							title: '家庭医生',
						},
						{
							src: require('@/assets/img/prod1/fw/5.png'),
							title: '电商',
						},
						{
							src: require('@/assets/img/prod1/fw/6.png'),
							title: '护理服务',
						},
						{
							src: require('@/assets/img/prod1/fw/7.png'),
							title: '订单服务',
						},
						{
							src: require('@/assets/img/prod1/fw/8.png'),
							title: '健康管理',
						},
						{
							src: require('@/assets/img/prod1/fw/9.png'),
							title: '志愿者',
						},
						{
							src: require('@/assets/img/prod1/fw/10.png'),
							title: '时间银行',
						},
						{
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/fw/11.png'),
							title: '助洁服务',
						},
						{
							src: require('@/assets/img/prod1/fw/12.png'),
							title: '助行服务',
						},
						{
							title: ' ',
						},
						{
							title: ' ',
						},
					]
				},
				{
					id: '7',
					desc: '养老大数据分析包括：自理能力、健康评估、兴趣爱好、饮食习惯、睡眠评估、活动规律、营养评估、消费分析、心里评估、年龄性别结果分析等。随着人工智能融入养老行业，高厚科技通过整合养老行业大数据，建立模型，分析长者的健康趋势、生活习惯、活动规律和兴趣喜好，为养老运营机构开展个性化服务提供数据决策支持。',
					imgObj: [
						{
							src: require('@/assets/img/prod1/sj/1.png'),
							title: '自理能力',
						},
						{
							src: require('@/assets/img/prod1/sj/2.png'),
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/sj/3.png'),
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/sj/4.png'),
							title: '健康评估',
						},
						{
							src: require('@/assets/img/prod1/sj/5.png'),
							title: '兴趣爱好',
						},
						{
							src: require('@/assets/img/prod1/sj/6.png'),
							title: '饮食习惯',
						},
						{
							src: require('@/assets/img/prod1/sj/7.png'),
							title: '睡眠评估',
						},
						{
							src: require('@/assets/img/prod1/sj/8.png'),
							title: '活动规律',
						},
						{
							src: require('@/assets/img/prod1/sj/9.png'),
							title: '营养评估',
						},
						{
							src: require('@/assets/img/prod1/sj/10.png'),
							title: '消费分析',
						},
						{
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/sj/11.png'),
							title: '心理评估',
						},
						{
							src: require('@/assets/img/prod1/sj/12.png'),
							title: '年龄性别分析',
						},
						{
							src: require('@/assets/img/prod1/sj/13.png'),
							title: '生活习惯',
						},
						{
							title: ' ',
						},
					]
				},
				{
					id: '8',
					desc: '包括：智能呼叫终端、智能定位终端、智能健康监测终端、智能家居终端、智能安防终端、智能医疗终端。平台融入丰富的智能设备，平台接口的开放性，保证对接所有智能终端。把智能设备测的数据实时上传到平台，如：血压计、血糖仪、血氧仪、体脂仪、体重计、大型健康一体机、智能床垫、腕表、手环、紧急呼叫按键、烟雾传感器、红外传感器、摄像头、智能家庭网关、定位器、医疗级诊断仪、理疗仪等。',
					imgObj: [
						{
							src: require('@/assets/img/prod1/zd/1.png'),
							title: '一键呼叫',
						},
						{
							src: require('@/assets/img/prod1/zd/2.png'),
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/zd/3.png'),
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/zd/4.png'),
							title: '被动红外探测器',
						},
						{
							src: require('@/assets/img/prod1/zd/5.png'),
							title: '单体门磁传感器',
						},
						{
							src: require('@/assets/img/prod1/zd/6.png'),
							title: '睡眠监测带',
						},
						{
							src: require('@/assets/img/prod1/zd/7.png'),
							title: '老年手机',
						},
						{
							src: require('@/assets/img/prod1/zd/8.png'),
							title: '健康手环',
						},
						{
							src: require('@/assets/img/prod1/zd/9.png'),
							title: '智能门磁',
						},
						{
							src: require('@/assets/img/prod1/zd/10.png'),
							title: '紧急呼叫',
						},
						{
							title: '血糖仪',
							src: require('@/assets/img/prod1/zd/15.png'),
						},
						{
							src: require('@/assets/img/prod1/zd/11.png'),
							title: '天猫精灵',
						},
						{
							title: '血氧仪',
							src: require('@/assets/img/prod1/zd/14.png'),
						},
						{
							src: require('@/assets/img/prod1/zd/12.png'),
							title: '监控设备',
						},
						{
							title: '血压仪',
							src: require('@/assets/img/prod1/zd/13.png'),
						},
					]
				},
				{
					id: '9',
					desc: '包括：大屏展示、老人分布、CRM、财务管理、一卡通、服务商管理、知识库、订单分析。实现夸区域统一结算，规范财务管理；运营数据简洁明了，便于查看和决策；盈利模式多样，保证市场运营持续发展；市场前瞻预测，公司及时调整运营决策。',
					imgObj: [
						{
							src: require('@/assets/img/prod1/yy/1.png'),
							title: '大屏展示',
						},
						{
							src: require('@/assets/img/prod1/yy/2.png'),
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/yy/3.png'),
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/yy/4.png'),
							title: '老人分布',
						},
						{
							src: require('@/assets/img/prod1/yy/5.png'),
							title: 'CRM',
						},
						{
							title: ' ',
						},
						{
							src: require('@/assets/img/prod1/yy/6.png'),
							title: '财务管理',
						},
						{
							src: require('@/assets/img/prod1/yy/7.png'),
							title: '知识库',
						},
						{
							src: require('@/assets/img/prod1/yy/8.png'),
							title: '订单分析',
						},
						{
							title: ' ',
						},
					]
				},
			],
			iconIdx: 0,
			iconList: [
				{	
					id: '1',
					title: '智慧会员', 
					src: require('@/assets/img/proIcon/icon_11.png'),
				},
				{
					id: '2',
					title: '智慧照护', 
					src: require('@/assets/img/proIcon/icon_12.png'),
				},
				{
					id: '3',
					title: '智慧机构', 
					src: require('@/assets/img/proIcon/icon_13.png'),
				},
				{
					id: '4',
					title: '智慧呼叫', 
					src: require('@/assets/img/proIcon/icon_14.png'),
				},
				{
					id: '5',
					title: '智慧政府', 
					src: require('@/assets/img/proIcon/icon_16.png'),
				},
				{
					id: '6',
					title: '智慧服务', 
					src: require('@/assets/img/proIcon/icon_17.png'),
				},
				{
					id: '7',
					title: '智慧数据', 
					src: require('@/assets/img/proIcon/icon_20.png'),
				},
				{
					id: '8',
					title: '智慧终端', 
					src: require('@/assets/img/proIcon/icon_22.png'),
				},
				{
					id: '9',
					title: '智慧运营', 
					src: require('@/assets/img/proIcon/icon_19.png'),
				},
				{
					id: '10',
					title: '智慧维护', 
					src: require('@/assets/img/proIcon/icon_15.png'),
				},
				{
					id: '11',
					title: '智慧生态', 
					src: require('@/assets/img/proIcon/icon_18.png'),
				},
				{
					id: '12',
					title: '智慧移动', 
					src: require('@/assets/img/proIcon/icon_21.png'),
				}
			],
			tabList: [{
					title: '养老服务', 
					src: require('@/assets/img/proIcon/icon_1.png'),
					subtitle: 'Call center',
					img: require('@/assets/img/proIcon/tab_1.png'),
					desc: '呼叫中心是智慧养老综合服中重要的一环，智慧养老用户通过老人手机发起的需求、紧急求助、咨询建议等都会率先进入到呼叫中心。'
				},{
					title: '呼叫中心', 
					src: require('@/assets/img/proIcon/icon_2.png'),
					subtitle: 'Call center',
					img: require('@/assets/img/proIcon/tab_1.png'),
					desc: '呼叫中心是智慧养老综合服中重要的一环，智慧养老用户通过老人手机发起的需求、紧急求助、咨询建议等都会率先进入到呼叫中心。24小时坐席，需要帮助时按“绿建”，客服人员就能通过呼叫中心管理系统弹窗显示老人的详细信息和地理位置，通过这些信息第一时间为老人服务，保障老年人不论何时何地有需求都会有人受理，紧急情况下咯爱人可通过“SOS”就行求助，完成服务后老人可对其工作人员的服务质量打分及服务反馈，系统收集信息并形成养老服务大数据定期回访老年人。'
				},{
					title: '线下支撑', 
					src: require('@/assets/img/proIcon/icon_3.png'),
					subtitle: 'Call center',
					img: require('@/assets/img/proIcon/tab_1.png'),
					desc: '呼叫中心是智慧养老综合服中重要的一环，智慧养老用户通过老人手机发起的需求、紧急求助、咨询建议等都会率先进入到呼叫中心。'
				},{
					title: '服务运营', 
					src: require('@/assets/img/proIcon/icon_4.png'),
					subtitle: 'Call center',
					img: require('@/assets/img/proIcon/tab_1.png'),
					desc: '呼叫中心是智慧养老综合服中重要的一环，智慧养老用户通过老人手机发起的需求、紧急求助、咨询建议等都会率先进入到呼叫中心。'
				},{
					title: '服务运营', 
					src: require('@/assets/img/proIcon/icon_4.png'),
					subtitle: 'Call center',
					img: require('@/assets/img/proIcon/tab_1.png'),
					desc: '呼叫中心是智慧养老综合服中重要的一环，智慧养老用户通过老人手机发起的需求、紧急求助、咨询建议等都会率先进入到呼叫中心。'
				},{
					title: '服务运营', 
					src: require('@/assets/img/proIcon/icon_4.png'),
					subtitle: 'Call center',
					img: require('@/assets/img/proIcon/tab_1.png'),
					desc: '呼叫中心是智慧养老综合服中重要的一环，智慧养老用户通过老人手机发起的需求、紧急求助、咨询建议等都会率先进入到呼叫中心。'
				},{
					title: '服务运营', 
					src: require('@/assets/img/proIcon/icon_4.png'),
					subtitle: 'Call center',
					img: require('@/assets/img/proIcon/tab_1.png'),
					desc: '呼叫中心是智慧养老综合服中重要的一环，智慧养老用户通过老人手机发起的需求、紧急求助、咨询建议等都会率先进入到呼叫中心。'
				},{
					title: '服务运营', 
					src: require('@/assets/img/proIcon/icon_4.png'),
					subtitle: 'Call center',
					img: require('@/assets/img/proIcon/tab_1.png'),
					desc: '呼叫中心是智慧养老综合服中重要的一环，智慧养老用户通过老人手机发起的需求、紧急求助、咨询建议等都会率先进入到呼叫中心。'
				},{
					title: '服务运营', 
					src: require('@/assets/img/proIcon/icon_4.png'),
					subtitle: 'Call center',
					img: require('@/assets/img/proIcon/tab_1.png'),
					desc: '呼叫中心是智慧养老综合服中重要的一环，智慧养老用户通过老人手机发起的需求、紧急求助、咨询建议等都会率先进入到呼叫中心。'
				},
			],
			swiperOption: {	// swiper 配置项
				loop : true,
				autoplay: {
					disableOnInteraction: false,
					delay: 5000
				},
				pagination: {
					el: '.swiper-pagination'
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			},
			swiperSlides: null, // 智能硬件数组
		}
	},
	created () {},
	mounted () {
		window.scrollTo(0, 0);

		// 模拟 swiper 数据
		const dataList = [
			{
				src: require('@/assets/img/proIcon/swiper1.png'),
				title: '老人手机',
				desc: '购买老人手机即可获得，24小时手机定位功能,享受红键紧急求助，绿键生活服务功能。'
			},{
				src: require('@/assets/img/proIcon/swiper2.png'),
				title: '健康手环',
				desc: '24小时监控佩戴者健康状况，紧急状况时，可按下“SOS”键呼叫坐席人员处理紧急求助。'
			},{
				src: require('@/assets/img/proIcon/swiper3.png'),
				title: '智能门磁',
				desc: '实时监控、远程报警，系统随即自动帮你跳转到摄像头的APP，让你查看实时摄像。'
			},{
				src: require('@/assets/img/proIcon/swiper4.png'),
				title: '紧急按钮',
				desc: '一款小巧的智能设备，直接按下按钮，第一时间联系到紧急联系人。'
			},{
				src: require('@/assets/img/proIcon/swiper5.png'),
				title: '天猫精灵无线按钮',
				desc: '一款低功耗的新型遥控开关，小巧，可实现门铃、一键开关执行天猫精灵组合命令的功能。 '
			},{
				src: require('@/assets/img/proIcon/swiper6.png'),
				title: '监控设备',
				desc: '采用低功耗嵌入式工控主机和嵌入式组态监控系统，系统部署快捷，稳定可靠。'
			},{
				src: require('@/assets/img/proIcon/swiper7.png'),
				title: '紧急按钮',
				desc: '如发生紧急意外如跌倒，需要帮助按下红色按钮，无线报警器则会发出进行紧急呼救，提醒家人。'
			},{
				src: require('@/assets/img/proIcon/swiper8.png'),
				title: '防被动红外探测器',
				desc: '窗前红外感应，误闯及时触发报警信息推送提醒家人。'
			},{
				src: require('@/assets/img/proIcon/swiper9.png'),
				title: '单体门磁传感器',
				desc: '监控日常开关门频次，长时间无开关门动作，系统会判断老人在家是否出现安全问题，发出紧急预警。'
			},{
				src: require('@/assets/img/proIcon/swiper10.png'),
				title: '睡眠监测带',
				desc: '智能设备联动，室内安全，实时检测，自动发出警报与app告警推送，老人安全，严密布防。'
			},
		]
		let pages = []
		dataList.forEach((item, index) => {
			const page = Math.floor(index / 6)
			if (!pages[page])
				pages[page] = []
			pages[page].push(item)
		})
		this.swiperSlides = pages
	},
	methods: {
		tapIcon (i, Id) {
			this.iconIdx = i
			window.scrollTo({
				behavior: "smooth",
				top: document.getElementById(Id).offsetTop - 74
			});
		},
		linkTo (routerName) {
			this.$router.push(routerName)
		},
		mouseEnter (i) {
			$('.cursor').children('.tab_label').removeClass('tab_active');
			$('.cursor').eq(i).children('.tab_label').addClass('tab_active');
			$('.product-wrap div.product').eq(i).fadeIn(150).siblings('div.product').hide();
			const liWidth = $('.cursor').width();
			$('.triangle2').stop(false, true).animate({
				'left': i * liWidth + 'px'
			}, 300);
		}
	}
}

</script>
<style lang='less' scoped>
@import '~@/assets/css/jq22.css';
@media screen and (max-width: 767px) {	// 移动端
	.title {
		margin: 20px 0;
		text-align: center;
		font-size: 25px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 500;
		color: #333333;
	}
	.line_block {
		margin: 0 auto;
		width: 25px;
		height: 1px;
		background: #2890FC;
	}
	.tab_box {
		margin-top: 20px;
		position: relative;
		.triangle2 {
			position: absolute;
			left: 0;
			bottom: -3px;
			width: 25%;
			overflow: hidden;
			text-align: center;
			b {
				display: inline-block;
				width: 0;
				height: 0;
				margin-top: -3px;
				border-style: dashed dashed solid dashed;
				border-width: 8px;
				border-color: #fff #fff #ECF4F9 #fff;
				overflow: hidden;
				zoom: 1;
				font-size: 0;	
			}
		}
		.cursor {
			width: 25%;
			display: inline-block;
			cursor: pointer;
			.icon_box {
				width: 42px;
				height: 42px;
				margin: auto;
				border-radius: 50%;
				background: #2890FC;
				text-align: center;
				.icon_img {
					margin-top: 5px;
					width: 30px;
					height: 30px;
				}
			}
			.tab_label {
				text-align: center;
				margin-top: 6px;
				font-size: 14px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 400;
				color: #2E4446;
			}
			.tab_active	{
				color: #2890FC !important;
			}
			.triangle {
				width: 0;
				height: 0;
				margin: auto;
				border: 8px solid;
				border-color: transparent;
			}
		}
	}
	.tab_link {
		width: 100%;
		background-color: #ECF4F9;
		.link_content {
			height: auto;
			margin: auto;
			box-sizing: border-box;
			padding: 15px;
			.con_left {
				font-family: SourceHanSansCN-Medium, SourceHanSansCN;
				.con_title {
					font-size: 19px;
					font-weight: 500;
					color: #302E46;
				}
				.con_subtitle {
					font-family: SourceHanSansCN-Regular, SourceHanSansCN;
					margin-top: 12px;
					font-size: 11px;
					font-weight: 400;
					color: #6C7C7D;
				}
				.con_desc {		
					margin: 10px 0;	
					font-size: 14px;
					line-height: 1.5;
					letter-spacing: 1px;
					font-family: SourceHanSansCN-Regular, SourceHanSansCN;
					font-weight: 400;
					color: #6C7C7D;
				}
			}
			.con_img {
				width: 100%;
			}
		}
	}
	.root {
		background: white;
	}
	.swiper-container {
		margin-top: 20px;
		.swiper-slide {
			display: flex;
			flex-wrap: wrap;
			.slide_box {
				width: 50%;
				padding: 0 15px;
				box-sizing: border-box;
				.slide_img {
					width: 113px;
					height: 113px;
					box-shadow: 0px 0px 5px 0px rgba(209, 209, 209, 0.5);
				}
				.slide_intro {
					width: 120px;
					min-height: 100px;
					padding: 6px 5px;
					position: relative;
					left: 38px;
					top: -18px;
					background: rgba(241, 241, 241, .7);
					box-sizing: border-box;
					font-family: SourceHanSansCN-Medium, SourceHanSansCN;
					color: #1F1836;
					overflow: hidden;
					.intro_title {				
						font-size: 14px;
						font-weight: 500;
					}
					.intro_desc {
						margin-top: 3px;
						font-size: 11px;
						font-weight: 400;
					}
					.rect_top {
						width: 18px;
						height: 18px;
						position: absolute;
						right: 0;
						z-index: 1;
						top: 0;
						background: #2890FC;
					}
				}
			}
		}
		.mobile-hide {
			display: none;
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {	// iPad
	
}

@media screen and (min-width: 992px) {	// PC端
	.title {
		margin-top: 80px;
		margin-bottom: 43px;
		text-align: center;
		font-size: 34px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 400;
		color: #1F1836;
	}
	.line_block {
		margin: 0 auto;
		width: 51px;
		height: 2px;
		background: #2890FC;
	}
	.pension_desc {
		width: 1200px;
		letter-spacing: 2px;
		line-height: 1.5;
		margin: 40px auto 40px;
		font-size: 20px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #666666;
		text-indent: 2em;
	}
	.tab_box {
		width: 900px;
		// width: 1200px;
		margin: 73px auto 0;
		position: relative;
		.triangle2 {
			position: absolute;
			left: 0;
			bottom: -3px;
			width: 300px;
			overflow: hidden;
			text-align: center;
			b {
				display: inline-block;
				width: 0;
				height: 0;
				margin-top: -3px;
				border-style: dashed dashed solid dashed;
				border-width: 16px;
				border-color: #fff #fff #ECF4F9 #fff;
				overflow: hidden;
				zoom: 1;
				font-size: 0;	
			}
		}
		display: flex;
		flex-wrap: wrap;
		.cursor {
			width: 150px;
			margin-bottom: 40px;
			// width: 25%;
			display: inline-block;
			cursor: pointer;
			.icon_box {
				width: 74px;
				height: 74px;
				margin: auto;
				border-radius: 50%;
				background: #2890FC;
				text-align: center;
				.icon_img {
					margin-top: 12px;
					width: 50px;
					height: 50px;
				}
			}
			.tab_active	{
				color: #2890FC !important;
			}
			.tab_label {
				color: #2E4446;
				text-align: center;
				margin-top: 12px;
				font-size: 18px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 400;
			}
			.triangle {
				width: 0;
				height: 0;
				margin: auto;
				border: 16px solid;
				border-color: transparent;
			}
		}
	}
	.tab_img_box {
		width: 1200px;
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		.img_con {
			width: 20%;
			box-sizing: border-box;
			position: relative; 
			height: 163px;
			margin-bottom: 4px;
			.tab_img {
				width: 98%;
				display: block;
				background: rgba(0, 0, 0, .2);
				height: 163px;
			}
			.tab_bott {
				width: 98%;
				text-align: center;
				font-size: 22px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 400;
				color: #FFFFFF;
				padding: 10px 0;
				position: absolute;
				bottom: 0;
				background: rgba(0, 0, 0, .2);
			}

		}
	}
	.tab_link {
		width: 100%;
		background-color: #ECF4F9;
		.link_content {
			width: 1200px;
			height: auto;
			box-sizing: border-box;
			margin: auto;
			padding: 60px 20px;
			.con_left {
				width: 438px;
				float: left;
				font-family: SourceHanSansCN-Medium, SourceHanSansCN;
				.con_title {
					margin-top: 10px;
					font-size: 28px;
					font-weight: 500;
					color: #302E46;
				}
				.con_subtitle {
					margin-top: 28px;
					font-size: 18px;
					font-weight: 400;
					color: #6C7C7D;
				}
				.con_desc {		
					margin-top: 28px;	
					font-size: 16px;
					line-height: 1.5;
					letter-spacing: 1px;
					font-family: SourceHanSansCN-Regular, SourceHanSansCN;
					font-weight: 400;
					color: #6C7C7D;
				}
			}
			.con_right {
				width: 680px;
				height: 400px;
				float: right;
				.con_img {
					width: auto;
					max-width: 100%;
				}
			}
		}
	}
	.root {
		background: white;
	}
	.swiper-container {
		width: 1200px;
		padding: 70px 0 80px;
		.swiper-slide {
			display: flex;
			flex-wrap: wrap;
			.slide_box {
				width: calc((1200px - 120px) / 3);
				padding-left: 90px;
				padding-top: 37px;
				box-sizing: border-box;
				.slide_img {
					width: 204px;
					height: 204px;
					box-shadow: 0px 0px 10px 0px rgba(209, 209, 209, 0.5);
				}
				.slide_intro {
					overflow: hidden;
					cursor: pointer;
					position: relative;
					left: 76px;
					top: -30px;
					background: rgba(241, 241, 241, .7);
					width: 215px;
					min-height: 126px;
					box-sizing: border-box;
					padding: 24px 10px;
					font-family: SourceHanSansCN-Medium, SourceHanSansCN;
					color: #1F1836;
					z-index: 2;
					@keyframes animateHover {
						to { 
							width: 100%; 
							height: 100%; 
							background: rgba(0, 126, 255, .7); 
						}
					}
					@-moz-keyframes animateHover { /* Firefox */
						to { 
							width: 100%; 
							height: 100%; 
							background: rgba(0, 126, 255, .7); 
						}
					}
					@-moz-keyframes animateHover { /* Safari 和 Chrome */
						to { 
							width: 100%; 
							height: 100%; 
							background: rgba(0, 126, 255, .7); 
						}
					}
					@-moz-keyframes animateHover { /* Opera */
						to { 
							width: 100%; 
							height: 100%; 
							background: rgba(0, 126, 255, .7); 
						}
					}
					&:hover {
						.rect_top {
							animation: animateHover .5s;
							-moz-animation: animateHover .5s;	/* Firefox */
							-webkit-animation: animateHover .5s;	/* Safari 和 Chrome */
							-o-animation: animateHover .5s;		/* Opera */
							animation-fill-mode: forwards;
							-moz-animation-fill-mode: forwards;
							-webkit-animation-fill-mode: forwards;
							-o-animation-fill-mode: forwards;
						}
						.intro_title, .intro_desc {
							color: white;
						}
						.rect_bottom {
							display: block;
						}
					}
					.rect_top, .rect_bottom {
						width: 33px;
						height: 33px;
						position: absolute;
						right: 0;
						z-index: -1;
					}
					.rect_top {
						top: 0;
						background: #2890FC;
					}
					.rect_bottom {
						background:#F8A003;
						bottom: 0;
						display: none;
					}
					.intro_title {				
						font-size: 18px;
						font-weight: 500;
					}
					.intro_desc {
						margin-top: 6px;
						font-size: 14px;
						font-weight: 400;
					}
				}
			}
		}
		.pc-hide {
			display: none;
		}
		.swiper-button-prev, .swiper-button-next {
			width: 60px;
			height: 60px;
			margin-top: -30px;
			border-radius: 50%;
			box-shadow: 0px 0px 6px 0px rgba(193, 193, 193, 0.35);
		}
		.swiper-button-prev {
			left: 6px;
			background-image: url('~@/assets/img/proIcon/prev.png');
			background-size: 30px 30px;
		}
		.swiper-button-next {
			right: 6px;
			background-image: url('~@/assets/img/proIcon/next.png');
			background-size: 30px 30px;
		}
	}
}
</style>